// client/src/components/ResetPassword.js
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './styles/ResetPassword.css'; // Ensure this file exists for styling

const ResetPassword = () => {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleResetPassword = async () => {
        if (password !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }

        const response = await fetch(`/api/auth/reset-password/${token}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ password })
        });

        if (response.ok) {
            setMessage('Password successfully reset!');
        } else {
            setMessage('Failed to reset password. The link might have expired.');
        }
    };

    return (
        <div className="reset-password-container">
            <img src="/logo.png" alt="Logo" className="logo" /> {/* Add the logo here */}
            <h2>Reset Password</h2>
            <input
                type="password"
                placeholder="New Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
            />
            <input
                type="password"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
            />
            <button onClick={handleResetPassword}>Reset Password</button>
            {message && <p>{message}</p>}
        </div>
    );
};

export default ResetPassword;
