import React, { useState, useEffect } from 'react';
import './ManageDashboard.css';  // Make sure this is the correct path


const ManageDashboard = () => {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [basePoints, setBasePoints] = useState([]);
    const [editPoint, setEditPoint] = useState(null);
    const [newPoint, setNewPoint] = useState({ name: '', latitude: '', longitude: '', height: 30 });

    useEffect(() => {
        // Fetch base points on component mount
        fetchBasePoints();
    }, []);

    const fetchBasePoints = async () => {
        try {
            const response = await fetch('/api/get-base-points'); // API call with correct endpoint
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                const data = await response.json();
                setBasePoints(data);
            } else {
                setMessage('Error: Non-JSON response received.');
            }
        } catch (error) {
            console.error('Error fetching base points:', error);
            setMessage('Error fetching base points.');
        }
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('kmzFile', file);

        try {
            const response = await fetch('/api/upload-kmz', {
                method: 'POST',
                body: formData,
            });
            const data = await response.text();
            setMessage(data);
            fetchBasePoints(); // Refresh the base points after upload
        } catch (error) {
            console.error('Error uploading KMZ:', error);
            setMessage('Error uploading KMZ.');
        }
    };

    const handleEditPoint = (point) => {
        setEditPoint(point); // Set the selected base point for editing
    };

    const handleSaveEdit = async (id) => {
        try {
            const response = await fetch(`/api/edit-base-point/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editPoint),
            });
            const result = await response.json();
            setMessage(result.message);
            setEditPoint(null); // Clear edit state after saving
            fetchBasePoints(); // Refresh the base points after saving
        } catch (error) {
            console.error('Error saving base point:', error);
            setMessage('Error saving base point.');
        }
    };

    const handleDeletePoint = async (id) => {
        try {
            const response = await fetch(`/api/delete-base-point/${id}`, {
                method: 'DELETE',
            });
            const result = await response.json();
            setMessage(result.message);
            fetchBasePoints(); // Refresh the base points after deletion
        } catch (error) {
            console.error('Error deleting base point:', error);
            setMessage('Error deleting base point.');
        }
    };

    const handleAddPoint = async () => {
        try {
            const response = await fetch('/api/add-base-point', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newPoint),
            });
            const result = await response.json();
            setMessage(result.message);
            setNewPoint({ name: '', latitude: '', longitude: '', height: 30 }); // Clear new point state after adding
            fetchBasePoints(); // Refresh the base points after adding
        } catch (error) {
            console.error('Error adding base point:', error);
            setMessage('Error adding base point.');
        }
    };

    const handleChange = (e) => {
        setEditPoint({
            ...editPoint,
            [e.target.name]: e.target.value,
        });
    };

    const handleNewPointChange = (e) => {
        setNewPoint({
            ...newPoint,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div>
            <h1>Manage Dashboard</h1>

            <h2>Upload KMZ File</h2>
            <input type="file" onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload</button>
            {message && <p>{message}</p>}

            <h2>Base Points</h2>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Latitude</th>
                        <th>Longitude</th>
                        <th>Height</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {basePoints.map((point) => (
                        <tr key={point.id}>
                            {editPoint && editPoint.id === point.id ? (
                                <>
                                    <td>
                                        <input
                                            type="text"
                                            name="name"
                                            value={editPoint.name}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="latitude"
                                            value={editPoint.latitude}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="longitude"
                                            value={editPoint.longitude}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="height"
                                            value={editPoint.height}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={() => handleSaveEdit(point.id)}>Save</button>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{point.name}</td>
                                    <td>{point.latitude}</td>
                                    <td>{point.longitude}</td>
                                    <td>{point.height}</td>
                                    <td>
                                        <button onClick={() => handleEditPoint(point)}>Edit</button>
                                        <button onClick={() => handleDeletePoint(point.id)}>Delete</button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>

            <h2>Add New Base Point</h2>
            <input
                type="text"
                placeholder="Name"
                name="name"
                value={newPoint.name}
                onChange={handleNewPointChange}
            />
            <input
                type="text"
                placeholder="Latitude"
                name="latitude"
                value={newPoint.latitude}
                onChange={handleNewPointChange}
            />
            <input
                type="text"
                placeholder="Longitude"
                name="longitude"
                value={newPoint.longitude}
                onChange={handleNewPointChange}
            />
            <input
                type="text"
                placeholder="Height"
                name="height"
                value={newPoint.height}
                onChange={handleNewPointChange}
            />
            <button onClick={handleAddPoint}>Add Point</button>
        </div>
    );
};

export default ManageDashboard;
