import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/App.css'; // Assuming this is where styles are stored

const GOOGLE_API_KEY = 'AIzaSyAuC39UoplUhw-bFf1kcBegnm7j5RUvHhg'; // Ensure this key is correct and unrestricted

const loadGoogleAPIs = (onLoadCallback) => {
    // Check if the Google Maps script is already present
    if (!window.google) {
        const existingScript = document.getElementById('googleMaps');

        if (!existingScript) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=visualization`;
            script.id = 'googleMaps';
            script.async = true;
            script.defer = true;
            script.onload = onLoadCallback;
            script.onerror = () => {
                console.error("Failed to load Google Maps API script.");
                alert("Failed to load Google Maps API. Please check your API key and network connection.");
            };
            document.body.appendChild(script);
        } else {
            onLoadCallback();
        }
    } else {
        onLoadCallback();
    }
};

const LOSVisual = () => {
    const [radius, setRadius] = useState(4000); // Default radius
    const [towerHeight, setTowerHeight] = useState(30); // Default tower height
    const [observerPosition, setObserverPosition] = useState({ lat: null, lng: null });
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [heatmapData, setHeatmapData] = useState([]);
    const [mapsLoaded, setMapsLoaded] = useState(false); // Flag for Google Maps API
    const navigate = useNavigate();

    useEffect(() => {
        loadGoogleAPIs(() => {
            setMapsLoaded(true); // Set flag when APIs are loaded
            console.log("Google Maps API loaded successfully.");
        });
    }, []);

    const calculateViewshed = async () => {
        if (!observerPosition.lat || !observerPosition.lng) {
            alert('Please provide a valid observer position.');
            return;
        }

        setLoading(true); // Show loading indicator
        setProgress(0); // Reset progress bar

        try {
            const points = generateViewshedPoints(observerPosition, radius, 360); // Generate 360 points (1-degree intervals) around the observer
            const heatmapPoints = [];

            for (let i = 0; i < points.length; i++) {
                const point = points[i];
                const distance = calculateDistance(observerPosition.lat, observerPosition.lng, point.lat, point.lng);
                if (distance > radius) continue; // Skip points beyond the indicated range

                const path = `${observerPosition.lat},${observerPosition.lng}|${point.lat},${point.lng}`;
                const samples = 10; // Adjust based on the length of the path for accuracy
                
                const elevationUrl = `https://maps.googleapis.com/maps/api/elevation/json?path=${encodeURIComponent(path)}&samples=${samples}&key=${GOOGLE_API_KEY}`;
                const elevationResult = await fetch(elevationUrl);
                const elevationData = await elevationResult.json();

                if (elevationData.status !== 'OK') {
                    throw new Error('Error fetching elevation data');
                }

                const baseElevation = elevationData.results[0].elevation;
                const targetElevation = elevationData.results[elevationData.results.length - 1].elevation;
                const adjustedStartHeight = baseElevation + towerHeight;

                let clearLOS = true;

                // Check for obstructions in terrain
                for (let j = 1; j < elevationData.results.length - 1; j++) {
                    const intermediateElevation = elevationData.results[j].elevation;
                    const expectedElevation = adjustedStartHeight + ((targetElevation - adjustedStartHeight) / elevationData.results.length) * j;

                    if (intermediateElevation > expectedElevation) {
                        clearLOS = false;
                        break;
                    }
                }

                heatmapPoints.push({
                    location: new window.google.maps.LatLng(point.lat, point.lng),
                    weight: clearLOS ? 1 : 0 // 1 for visible, 0 for non-visible
                });

                // Update the progress bar
                setProgress(((i + 1) / points.length) * 100);
            }

            setHeatmapData(heatmapPoints);
            setLoading(false); // Hide loading indicator

        } catch (error) {
            console.error("Error during viewshed calculation:", error);
            alert("An error occurred during the viewshed calculation. Please try again.");
            setLoading(false); // Hide loading indicator
        }
    };

    const generateViewshedPoints = (center, radius, numPoints) => {
        const points = [];
        for (let i = 0; i < numPoints; i++) {
            const angle = (i / numPoints) * 2 * Math.PI;
            const dx = radius * Math.cos(angle);
            const dy = radius * Math.sin(angle);
            points.push({
                lat: center.lat + (dy / 111000), // Approx conversion from meters to degrees
                lng: center.lng + (dx / (111000 * Math.cos(center.lat * Math.PI / 180)))
            });
        }
        return points;
    };

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371e3; // Earth radius in meters
        const φ1 = lat1 * Math.PI / 180;
        const φ2 = lat2 * Math.PI / 180;
        const Δφ = (lat2 - lat1) * Math.PI / 180;
        const Δλ = (lon2 - lon1) * Math.PI / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                  Math.cos(φ1) * Math.cos(φ2) *
                  Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // Distance in meters
    };

    const renderMapWithHeatmap = () => {
        if (!window.google || !window.google.maps) {
            console.error('Google Maps API not loaded yet.');
            return;
        }

        const mapOptions = {
            zoom: 13,
            center: { lat: observerPosition.lat, lng: observerPosition.lng }
        };
        const map = new window.google.maps.Map(document.getElementById('viewshed-map'), mapOptions);

        const observerMarker = new window.google.maps.Marker({
            position: { lat: observerPosition.lat, lng: observerPosition.lng },
            map,
            title: 'Observer (Tower)'
        });

        const heatmap = new window.google.maps.visualization.HeatmapLayer({
            data: heatmapData,
            dissipating: false,
            map: map,
            radius: 50, // Radius for heatmap points
            opacity: 0.8,
            gradient: [
                'rgba(255, 0, 0, 0)', // Invisible
                'rgba(255, 0, 0, 1)', // Red for non-visible
                'rgba(0, 255, 0, 1)'  // Green for visible
            ]
        });

        heatmap.setMap(map);
    };

    useEffect(() => {
        if (mapsLoaded && heatmapData.length > 0) {
            renderMapWithHeatmap();
        }
    }, [heatmapData, mapsLoaded]);

    return (
        <div className="main-container">
            <img src="/logosmartmap.png" alt="Logo" className="logo" /> {/* Logo at the top */}
            <h2 className="tagline">LOS Visual: Viewshed Calculation</h2>

            <div>
                <h3>Observer Position (Tower)</h3>
                <label>
                    Latitude:
                    <input
                        type="number"
                        name="lat"
                        value={observerPosition.lat || ''}
                        onChange={(e) => setObserverPosition({ ...observerPosition, lat: parseFloat(e.target.value) })}
                        step="any"
                    />
                </label>
                <label>
                    Longitude:
                    <input
                        type="number"
                        name="lng"
                        value={observerPosition.lng || ''}
                        onChange={(e) => setObserverPosition({ ...observerPosition, lng: parseFloat(e.target.value) })}
                        step="any"
                    />
                </label>
            </div>
            <div>
                <h3>Height of Observer (Tower) (in meters)</h3>
                <input
                    type="number"
                    value={towerHeight}
                    onChange={(e) => setTowerHeight(parseFloat(e.target.value))}
                    step="any"
                />
            </div>
            <div>
                <h3>Radius from Observer (Max 4000m)</h3>
                <input
                    type="number"
                    value={radius}
                    onChange={(e) => setRadius(Math.min(parseFloat(e.target.value), 4000))}
                    step="any"
                />
            </div>
            <button onClick={calculateViewshed}>Calculate Viewshed</button>
            {loading && (
                <div>
                    <p>Calculating, please wait...</p>
                    <div style={{ width: '100%', backgroundColor: '#ccc', borderRadius: '10px', overflow: 'hidden' }}>
                        <div style={{
                            width: `${progress}%`,
                            backgroundColor: '#4aa0a9',
                            height: '24px',
                            transition: 'width 0.3s ease'
                        }}></div>
                    </div>
                </div>
            )}

            <div id="viewshed-map" style={{ height: '500px', width: '100%', marginTop: '20px' }}></div>

            {heatmapData.length > 0 && (
                <div>
                    <h3>Viewshed Results</h3>
                    <p>Total Points in Viewshed: {heatmapData.length}</p>
                </div>
            )}
        </div>
    );
};

export default LOSVisual;
