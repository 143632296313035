import React, { useState } from 'react';
import './styles/App.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [message, setMessage] = useState('');

    const handleLogin = async () => {
        const response = await fetch('/api/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        });

        if (response.ok) {
            const data = await response.json();
            localStorage.setItem('token', data.token);
            window.location.href = '/main';
        } else {
            alert('Login failed');
        }
    };

    const handleForgotPassword = async () => {
        const response = await fetch('/api/auth/forgot-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        });
    
        if (response.ok) {
            setMessage('Recovery email sent! Please check your inbox.');
        } else {
            setMessage('Failed to send recovery email. Please try again.');
        }
    };
    

    return (
        <div className="login-container">
            <img src="/logosmartmap.png" alt="Logo" className="logo" />
            <h2 className="tagline">Smartmap: Next Generation LOS Tool</h2>

            {!showForgotPassword ? (
                <>
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <button onClick={handleLogin}>Login</button>
                    <p className="forgot-password" onClick={() => setShowForgotPassword(true)}>Forgot Password?</p>
                </>
            ) : (
                <>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <button onClick={handleForgotPassword}>Send Recovery Email</button>
                    {message && <p className="message">{message}</p>}
                </>
            )}
        </div>
    );
};

export default Login;
