import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/App.css'; // Assuming this is where styles are stored

const GOOGLE_API_KEY = 'AIzaSyAuC39UoplUhw-bFf1kcBegnm7j5RUvHhg'; // Replace with your actual API key

const loadGoogleAPIs = (onLoadCallback) => {
    const existingScript = document.getElementById('googleMaps');

    if (!existingScript) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}`;
        script.id = 'googleMaps';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        const chartsScript = document.createElement('script');
        chartsScript.src = 'https://www.gstatic.com/charts/loader.js';
        chartsScript.async = true;
        document.body.appendChild(chartsScript);

        chartsScript.onload = () => {
            window.google.charts.load('current', { packages: ['corechart'] });
            window.google.charts.setOnLoadCallback(onLoadCallback);
        };

        script.onload = () => {
            onLoadCallback();
        };
    } else {
        onLoadCallback();
    }
};

const Main = () => {
    const [userRole, setUserRole] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false); // Loading state for data fetch
    const [losResults, setLosResults] = useState([]);
    const [elevationProfiles, setElevationProfiles] = useState([]);
    const [startPoint, setStartPoint] = useState({ lat: null, lng: null });
    const [heightAboveGround, setHeightAboveGround] = useState(0);
    const [maxDistance, setMaxDistance] = useState(8000); // Default to 8km maximum distance
    const [mapsLoaded, setMapsLoaded] = useState(false); // Flag for Google Maps & Charts
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const payload = JSON.parse(atob(token.split('.')[1]));
            setUserRole(payload.role);
        }

        loadGoogleAPIs(() => setMapsLoaded(true)); // Set flag when APIs are loaded
    }, []);

    const handleManageAccess = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('/manage', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Access denied');
            }
            navigate('/manage');
        } catch (error) {
            setErrorMessage(error.message);
        }
    };

    const calculateLineOfSight = async () => {
        if (!startPoint.lat || !startPoint.lng) {
            setErrorMessage('Starting Point is required.');
            return;
        }

        setLoading(true); // Show loading indicator

        try {
            const response = await fetch('/api/get-base-points');
            const basePoints = await response.json();

            const inRangePoints = basePoints.filter(basePoint => {
                const lat = parseFloat(basePoint.latitude);
                const lng = parseFloat(basePoint.longitude);

                if (isNaN(lat) || isNaN(lng)) {
                    console.log(`Invalid coordinates for base point ${basePoint.id}`);
                    return false; // Discard invalid points
                }

                const distance = calculateDistance(startPoint.lat, startPoint.lng, lat, lng);
                return distance <= maxDistance;
            });

            if (inRangePoints.length === 0) {
                setErrorMessage('No base points found within the specified range.');
                setLoading(false); // Hide loading indicator
                return;
            }

            const results = [];
            const elevationDataCollection = [];

            for (const basePoint of inRangePoints) {
                const lat = parseFloat(basePoint.latitude);
                const lng = parseFloat(basePoint.longitude);

                const elevationResult = await fetch(`/api/google-elevation?startLat=${startPoint.lat}&startLng=${startPoint.lng}&endLat=${lat}&endLng=${lng}&samples=100`);
                const elevationData = await elevationResult.json();

                if (elevationData.status !== 'OK') {
                    throw new Error('Error fetching elevation data');
                }

                const baseElevation = elevationData.results[0].elevation;
                const targetElevation = elevationData.results[elevationData.results.length - 1].elevation;
                const adjustedStartHeight = baseElevation + heightAboveGround;

                let clearLOS = true;

                // Check for obstructions in terrain
                for (let i = 1; i < elevationData.results.length - 1; i++) {
                    const intermediateElevation = elevationData.results[i].elevation;
                    const expectedElevation = adjustedStartHeight + ((targetElevation - adjustedStartHeight) / elevationData.results.length) * i;

                    if (intermediateElevation > expectedElevation) {
                        clearLOS = false;
                        break;
                    }
                }

                const distance = calculateDistance(startPoint.lat, startPoint.lng, lat, lng);
                const bearing = calculateBearing(startPoint.lat, startPoint.lng, lat, lng);
                const elevationAngle = calculateElevationAngle(startPoint.lat, startPoint.lng, lat, lng, adjustedStartHeight, targetElevation);

                if (clearLOS) {
                    results.push({
                        basePoint,
                        losResult: 'Clear line of sight',
                        distance,
                        bearing,
                        elevationAngle
                    });

                    elevationDataCollection.push({
                        basePoint,
                        elevationProfile: elevationData.results
                    });
                }
            }

            setLosResults(results);
            setElevationProfiles(elevationDataCollection);
            setLoading(false); // Hide loading indicator

        } catch (error) {
            setErrorMessage(error.message);
            setLoading(false); // Hide loading indicator
        }
    };

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371e3; // Earth radius in meters
        const φ1 = lat1 * Math.PI / 180;
        const φ2 = lat2 * Math.PI / 180;
        const Δφ = (lat2 - lat1) * Math.PI / 180;
        const Δλ = (lon2 - lon1) * Math.PI / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                  Math.cos(φ1) * Math.cos(φ2) *
                  Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // Distance in meters
    };

    const calculateBearing = (lat1, lon1, lat2, lon2) => {
        const φ1 = lat1 * Math.PI / 180;
        const φ2 = lat2 * Math.PI / 180;
        const λ1 = lon1 * Math.PI / 180;
        const λ2 = lon2 * Math.PI / 180;

        const y = Math.sin(λ2 - λ1) * Math.cos(φ2);
        const x = Math.cos(φ1) * Math.sin(φ2) -
                  Math.sin(φ1) * Math.cos(φ2) * Math.cos(λ2 - λ1);
        const θ = Math.atan2(y, x);
        const bearing = (θ * 180 / Math.PI + 360) % 360; // In degrees

        return bearing;
    };

    const calculateElevationAngle = (lat1, lon1, lat2, lon2, startHeight, endHeight) => {
        const horizontalDistance = calculateDistance(lat1, lon1, lat2, lon2);
        const heightDifference = endHeight - startHeight;
        const angle = Math.atan2(heightDifference, horizontalDistance) * (180 / Math.PI); // In degrees
        return angle;
    };

    const handleInputChange = (e, type) => {
        const { name, value } = e.target;
        if (type === 'start') {
            setStartPoint(prevState => ({
                ...prevState,
                [name]: parseFloat(value)
            }));
        } else if (type === 'height') {
            setHeightAboveGround(parseFloat(value));
        } else if (type === 'distance') {
            setMaxDistance(parseFloat(value));
        }
    };

    const renderMapAndElevationProfile = (elevationProfile, basePoint) => {
        if (!window.google || !window.google.maps || !window.google.visualization) {
            console.error('Google APIs not loaded yet.');
            return;
        }

        const mapOptions = {
            zoom: 10,
            center: { lat: startPoint.lat, lng: startPoint.lng }
        };
        const map = new window.google.maps.Map(document.getElementById(`map-${basePoint.id}`), mapOptions);

        const startMarker = new window.google.maps.Marker({
            position: { lat: startPoint.lat, lng: startPoint.lng },
            map,
            title: 'Start Point'
        });

        const endMarker = new window.google.maps.Marker({
            position: { lat: parseFloat(basePoint.latitude), lng: parseFloat(basePoint.longitude) },
            map,
            title: 'Base Point'
        });

        const flightPlanCoordinates = [
            { lat: startPoint.lat, lng: startPoint.lng },
            { lat: parseFloat(basePoint.latitude), lng: parseFloat(basePoint.longitude) }
        ];

        const flightPath = new window.google.maps.Polyline({
            path: flightPlanCoordinates,
            geodesic: true,
            strokeColor: '#FF0000',
            strokeOpacity: 1.0,
            strokeWeight: 2
        });

        flightPath.setMap(map);

        // Plot the elevation profile
        const chartData = new window.google.visualization.DataTable();
        chartData.addColumn('string', 'Sample');
        chartData.addColumn('number', 'Elevation');
        chartData.addColumn('number', 'LOS Line');

        const baseElevation = elevationProfile[0].elevation;
        const targetElevation = elevationProfile[elevationProfile.length - 1].elevation;
        const adjustedStartHeight = baseElevation + heightAboveGround;

        elevationProfile.forEach((point, index) => {
            const expectedElevation = adjustedStartHeight + ((targetElevation - adjustedStartHeight) / elevationProfile.length) * index;
            chartData.addRow([`Point ${index + 1}`, point.elevation, expectedElevation]);
        });

        const options = {
            title: 'Elevation Profile',
            legend: { position: 'bottom' },
            hAxis: { title: 'Distance' },
            vAxis: { title: 'Elevation (meters)' },
            height: 150,
            width: 500,
            series: {
                0: { color: '#1b9e77' }, // Terrain elevation
                1: { color: '#d95f02' }  // LOS line
            }
        };

        const chart = new window.google.visualization.LineChart(document.getElementById(`chart-${basePoint.id}`));
        chart.draw(chartData, options);
    };

    const renderStreetView = (basePoint, bearing) => {
        console.log(`Rendering Street View for base point ${basePoint.name} with bearing ${bearing}`);
    
        const streetViewOptions = {
            position: { lat: startPoint.lat, lng: startPoint.lng },
            pov: {
                heading: bearing, // Ensure this is the correct bearing
                pitch: 0
            },
            zoom: 1
        };
    
        // Log street view options for debugging
        console.log('Street View Options:', streetViewOptions);
    
        const panorama = new window.google.maps.StreetViewPanorama(
            document.getElementById(`streetview-${basePoint.id}`),
            streetViewOptions
        );
        
        panorama.setPov({ heading: bearing, pitch: 0 }); // Ensures the POV is updated
    };
    
    
    useEffect(() => {
        if (mapsLoaded && elevationProfiles.length > 0) {
            elevationProfiles.forEach(profile => {
                renderMapAndElevationProfile(profile.elevationProfile, profile.basePoint);
                renderStreetView(profile.basePoint, profile.bearing);
            });
        }
    }, [elevationProfiles, mapsLoaded]);

    return (
        <div className="main-container">
            <img src="/logosmartmap.png" alt="Logo" className="logo" /> {/* Logo at the top */}
            <h2 className="tagline">Smartmap: Next Generation LOS Tool</h2>

            {userRole && (userRole === 'admin' || userRole === 'superadmin') && (
                <>
                    <button onClick={handleManageAccess}>Go to Manage Base Points</button><button onClick={() => navigate('/los-visual')}>Area Visualisation</button>
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                </>
            )}

            <h2>Line of Sight Tool</h2>

            <div>
                <h3>Starting Point</h3>
                <label>
                    Latitude:
                    <input
                        type="number"
                        name="lat"
                        value={startPoint.lat || ''}
                        onChange={(e) => handleInputChange(e, 'start')}
                        step="any"
                    />
                </label>
                <label>
                    Longitude:
                    <input
                        type="number"
                        name="lng"
                        value={startPoint.lng || ''}
                        onChange={(e) => handleInputChange(e, 'start')}
                        step="any"
                    />
                </label>
            </div>
            <div>
                <h3>Height Above Ground (in meters)</h3>
                <input
                    type="number"
                    value={heightAboveGround}
                    onChange={(e) => handleInputChange(e, 'height')}
                    step="any"
                />
            </div>
            <div>
                <h3>Maximum Distance (in meters)</h3>
                <input
                    type="number"
                    value={maxDistance}
                    onChange={(e) => handleInputChange(e, 'distance')}
                    step="any"
                />
            </div>
            <button onClick={calculateLineOfSight}>Calculate Line of Sight</button>
            {loading && <p>Loading data, please wait...</p>} {/* Loading indicator */}
            
            {losResults.length > 0 && (
                <div>
                    <h3>LOS Results</h3>
                    <ul>
                        {losResults.map((result, index) => (
                            <li key={index} style={{ border: '1px solid grey', borderRadius: '6px', padding: '10px', marginBottom: '20px' }}>
                                <h4>{result.basePoint.name}</h4>
                                <p><strong>Distance:</strong> {result.distance.toFixed(2)} meters</p>
                                <p><strong>Bearing:</strong> {result.bearing.toFixed(2)}° | <strong>Elevation Angle:</strong> {result.elevationAngle.toFixed(2)}°</p>
                                <div id={`map-${result.basePoint.id}`} style={{ height: '300px', width: '500px', marginBottom: '10px', borderRadius: '6px' }}></div>
                                <div id={`chart-${result.basePoint.id}`} style={{ marginBottom: '10px', borderRadius: '6px' }}></div>
                                <div id={`streetview-${result.basePoint.id}`} style={{ height: '300px', width: '500px', borderRadius: '6px' }}></div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    );
};

export default Main;