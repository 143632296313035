import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Main from './components/Main';
import ResetPassword from './components/ResetPassword';
import ManageDashboard from './components/manage/ManageDashboard';
import LOSVisual from './components/LOSVisual'; // Import LOSVisual

function App() {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route path="/main" element={<Main />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/manage" element={<ManageDashboard />} />
                <Route path="/los-visual" element={<LOSVisual />} /> {/* LOS Visual Route */}
            </Routes>
        </Router>
    );
}

export default App;
